// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Box,
  Chip,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Add as AddIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SEARCH_URL = process.env.REACT_APP_WEB_API;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const allDomains = ['Technology', 'Business', 'Science', 'Defence & Security', 'Strategy', 'Geopolitical'];
const DomainsWithSub = [
  {
    name: 'Technology',
    fields: [
      'Artificial Intelligence',
      'Cybersecurity',
      'Data Science',
      'Internet of Things (IoT)',
      'Hardware',
      'Augmented Reality (AR)',
      'Virtual Reality (VR)',
      'Cloud Computing',
      'Robotics',
      'Quantum Computing',
    ],
  },
  {
    name: 'Business',
    fields: [
      'Finance',
      'Marketing',
      'Entrepreneurship',
      'Management',
      'Human Resources',
      'Supply Chain Management',
      'E-commerce',
      'Strategic Planning',
      'Business Analytics',
      'Corporate Social Responsibility (CSR)',
    ],
  },
  {
    name: 'Science',
    fields: [
      'Biology',
      'Chemistry',
      'Physics',
      'Environmental Science',
      'Astronomy',
      'Neuroscience',
      'Genetics',
      'Geology',
      'Climate Science',
      'Materials Science',
    ],
  },
  {
    name: 'Defence & Security',
    fields: [
      'Military Strategy',
      'Cyber Warfare',
      'Ballistic Missiles',
      'Nuclear Deterrence',
      'Counterterrorism',
      'Military Technology',
      'Intelligence Analysis',
      'Defense Policy',
      'Homeland Security',
      'Special Operations',
    ],
  },
  {
    name: 'Strategy',
    fields: [
      'Business Strategy',
      'Military Strategy',
      'Marketing Strategy',
      'Competitive Strategy',
      'Digital Strategy',
      'Innovation Strategy',
      'Corporate Strategy',
      'Risk Management',
      'Brand Strategy',
      'Supply Chain Strategy',
    ],
  },
  {
    name: 'Geopolitical',
    fields: [
      'International Relations',
      'Geopolitical Risk Analysis',
      'Diplomacy',
      'Globalization',
      'Security Studies',
      'Geopolitical Economy',
      'Foreign Policy Analysis',
      'Regional Studies',
      'Conflict Resolution',
      'Terrorism Studies',
    ],
  },
];
export const CreateNewsLetter = (props) => {
  const [email, setEmail] = useState('');
  const [domains, setDomains] = useState([]);
  const [subDomains, setSubDomains] = useState([]);
  const [selectedSubDomains, setSelectedSubDomains] = useState([]);
  const [topics, setTopics] = useState(['']);
  const [sources, setSources] = useState(['']);
  const [frequently, setFrequently] = useState('');
  const [frequentlyMain, setFrequentlyMain] = useState('');
  const [frequentlyDay, setFrequentlyDay] = useState('');
  const [frequentlyTime, setFrequentlyTime] = useState('');
  const [frequentlyDayNum, setFrequentlyDayNum] = useState(null);
  const [showDay, setShowDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleChange = (event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event;
    setDomains(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    const selected = event.target.value;
    var subSelected: any[] | ((prevState: string[]) => string[]) = [];
    for (let index = 0; index < selected.length; index++) {
      for (let sub = 0; sub < DomainsWithSub.length; sub++) {
        if (DomainsWithSub[sub].name == selected[index]) {
          subSelected.push(...DomainsWithSub[sub].fields);
          setSelectedSubDomains([]);
        }
      }
    }
    setSubDomains(subSelected);
  };

  const handleSubDomainsChange = (event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event;
    setSelectedSubDomains(typeof value === 'string' ? value.split(',') : value);
  };

  const addTopic = () => {
    setTopics((prevMessages) => [...prevMessages, '']);
  };

  const removeTopic = (index) => {
    setTopics([...topics.slice(0, index), ...topics.slice(index + 1)]);
  };

  const addSource = () => {
    setSources((prevMessages) => [...prevMessages, '']);
  };

  const removeSource = (index) => {
    setSources([...sources.slice(0, index), ...sources.slice(index + 1)]);
  };

  const handleFrequently = (event) => {
    setFrequentlyMain(event.target.value);
    if (event.target.value !== 'Weekly') {
      setFrequently(event.target.value);
    } else {
      setFrequently('Saturday');
      setFrequentlyDay('Saturday');
    }
    if (event.target.value !== 'Monthly') {
      setFrequentlyDayNum(null);
    }
  };

  const selectFrequentlyDay = (event) => {
    setFrequentlyDay(event.target.value);
    setFrequently(event.target.value);
  };

  const selectDay = (e) => {
    e.preventDefault();
    setShowDay(!showDay);
  };

  const handleSelectDay = (day) => {
    setFrequentlyDayNum(day);
    setShowDay(false);
  };
  const [hasInvalidSources, setHasInvalidSources] = useState(false);
  const handleCreate = () => {
    const filteredTopics = topics.filter((el) => el?.trim().length !== 0 && el != null);
    const filteredSources = sources.filter((el) => el?.trim().length !== 0 && el != null);

    // URL validation for sources
    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    };

    const invalidSources = filteredSources.some(source => !isValidUrl(source));
    setHasInvalidSources(invalidSources); // Set state based on URL validity

    if (invalidSources) {
      setOpenFailed(true); // Open Snackbar for invalid URLs
      return; // Exit if any source is invalid
    }

    setLoading(true);
    const data = {
      email,
      domains,
      sub_domains: selectedSubDomains,
      topics: filteredTopics,
      sources: filteredSources,
      frequently,
      frequently_time: frequentlyTime,
      frequently_day_num: frequentlyDayNum,
      status: 0,
    };
    const user = localStorage.getItem('user');
    const token = JSON.parse(user).token;
    axiosBackendApi
      .post(`${SEARCH_URL}/newsletters`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOpenSuccess(true);
        setTimeout(() => {
          props.onCreateClick();
        }, 500);
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          setErrors(error.response?.data?.errors ?? {});
        }
        setLoading(false);
        setOpenFailed(true);
      });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseFailed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailed(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.className !== 'frequentlyDayNumSpan' && e.target.id !== 'dat-time') {
      setShowDay(false);
    }
  };

  const didUnmount = useRef(false);
  useEffect(() => {
    const user = localStorage.getItem('user');
    setEmail(JSON.parse(user).email);
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      didUnmount.current = true;
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="custom-form">
      <FormControl sx={{ width: '100%' }}>
        <label htmlFor="email" className="mb-1 d-block">
          {props.messages.Email} <span className="text-danger text-bold">*</span>
        </label>
        <OutlinedInput id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {errors.email ? <small className="text-danger">{errors.email}</small> : ''}
      </FormControl>
      <FormControl sx={{ width: '100%' }} className="mt-2">
        <label htmlFor="domains" className="mb-1 d-block">
          {props.messages.DomainsFields} <span className="text-danger text-bold">*</span>
        </label>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={domains}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Domains / Fields" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {allDomains.map((name, index) => (
            <MenuItem key={index} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {errors.domains ? <small className="text-danger">{errors.domains}</small> : ''}
      </FormControl>
      {domains.length ? (
        <FormControl sx={{ width: '100%' }} className="mt-2">
          <label htmlFor="subdomains" className="mb-1 d-block">
            {props.messages.SubDomains} <span className="text-danger text-bold">*</span>
          </label>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedSubDomains}
            onChange={handleSubDomainsChange}
            input={<OutlinedInput id="select-multiple-chip" label="Domains / Fields" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {subDomains.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {errors.sub_domains ? <small className="text-danger">{errors.sub_domains}</small> : ''}
        </FormControl>
      ) : null}
      {sources.map((source, index) => (
        <div className="multi-input mt-2 flex items-end gap-2 " key={index}>
          <FormControl sx={{ width: '100%' }}>
            {!index ? (
              <label htmlFor={`source-${index}`} className="mb-1 d-block">
                {props.messages.SpecificSources}
              </label>
            ) : null}
            <OutlinedInput
              id={`source-${index}`}
              type="text"
              defaultValue={source}
              onBlur={(e) => {
                setSources((prevArr) => {
                  const result = [...prevArr];
                  if (e.target?.value) {
                    result[index] = e.target.value;
                  }
                  return result;
                });
              }}
            />
          </FormControl>
          <div className="flex items-end gap-2">
            <IconButton className="icon-outline" color="primary" onClick={addSource}>
              <AddIcon />
            </IconButton>
            {index >= 1 && (
              <IconButton className="icon-outline" color="primary" onClick={() => removeSource(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
      <FormControl className="mt-2" fullWidth>
        <label className="mb-1 d-block">
          {props.messages.Scheduling} <span className="text-danger text-bold">*</span>
        </label>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={frequentlyMain}
          onChange={handleFrequently}
        >
          <MenuItem value={'Daily'}>{props.messages.Daily}</MenuItem>
          <MenuItem value={'Weekly'}>{props.messages.Weekly}</MenuItem>
          <MenuItem value={'Monthly'}>{props.messages.Monthly}</MenuItem>
        </Select>
      </FormControl>
      {frequentlyMain === 'Weekly' ? (
        <FormControl fullWidth>
          <label className="mb-1 mt-1 d-block">
            {props.messages.FrequentlyDay} <span className="text-danger text-bold">*</span>
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={frequentlyDay}
            onChange={selectFrequentlyDay}
          >
            <MenuItem value={'Saturday'}>{props.messages.Saturday}</MenuItem>
            <MenuItem value={'Sunday'}>{props.messages.Sunday}</MenuItem>
            <MenuItem value={'Monday'}>{props.messages.Monday}</MenuItem>
            <MenuItem value={'Tuesday'}>{props.messages.Tuesday}</MenuItem>
            <MenuItem value={'Wednesday'}>{props.messages.Wednesday}</MenuItem>
            <MenuItem value={'Thursday'}>{props.messages.Thursday}</MenuItem>
            <MenuItem value={'Friday'}>{props.messages.Friday}</MenuItem>
          </Select>
        </FormControl>
      ) : null}
      {errors.frequently ? <small className="text-danger">{errors.frequently}</small> : null}
      {frequentlyMain === 'Monthly' ? (
        <FormControl className="mt-2" fullWidth>
          <label className="mb-1 d-block">
            {props.messages.Day} <span className="text-danger text-bold">*</span>
          </label>
          <OutlinedInput id="dat-time" type="text" value={frequentlyDayNum} onClick={(e) => selectDay(e)} />
          <div className={showDay ? 'frequentlyDayNum show' : 'frequentlyDayNum'}>
            {[...Array(31)].map((e, i) => (
              <span
                key={i}
                onClick={() => handleSelectDay(i + 1)}
                className={frequentlyDayNum === i + 1 ? 'selected frequentlyDayNumSpan' : 'frequentlyDayNumSpan'}
              >
                {i + 1}
              </span>
            ))}
          </div>
          {errors.frequently_day_num ? <small className="text-danger">{errors.frequently_day_num}</small> : null}
        </FormControl>
      ) : null}
      {frequentlyMain ? (
        <FormControl className="mt-2" fullWidth>
          <label className="mb-1 d-block">
            {props.messages.Time} <span className="text-danger text-bold">*</span>
          </label>
          <OutlinedInput
            id="dat-time"
            type="time"
            value={frequentlyTime}
            onChange={(e) => {
              const time = e.target.value.split(":")[0];
              setFrequentlyTime(`${time}:00`);
            }}
          />
          {errors.frequently_time ? <small className="text-danger">{errors.frequently_time}</small> : null}
        </FormControl>
      ) : null}
      <div className="mt-2 pt-2">
        <Button
          variant="contained"
          color="success"
          className={loading ? 'cursor-not-allowed btn-default ' : 'btn-default create-newsletter-btn'}
          onClick={handleCreate}
          disabled={loading}
        >
          {props.messages.Create}
          {loading && <CircularProgress color="success" size={24} />}
        </Button>
      </div>
      <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
          className="alert-success"
        >
          {props.messages.CreatedSuccessfully}
        </Alert>
      </Snackbar>
      {/*<Snackbar open={openFailed} autoHideDuration={2500} onClose={handleCloseFailed}>*/}
      {/*  <Alert*/}
      {/*    className="alert-error"*/}
      {/*    onClose={handleCloseFailed}*/}
      {/*    severity="error"*/}
      {/*    variant="filled"*/}
      {/*    sx={{ width: '100%' }}*/}
      {/*  >*/}
      {/*    {props.messages.FailedToCreate}*/}
      {/*  </Alert>*/}
      {/*</Snackbar>*/}
      <Snackbar open={openFailed} autoHideDuration={6000} onClose={handleCloseFailed}>
        <Alert onClose={handleCloseFailed} severity="error" sx={{ width: '100%' }}>
          {hasInvalidSources ? "Please enter valid URLs in all source fields." : "Failed to create newsletter."}
        </Alert>
      </Snackbar>
    </div>
  );
};
